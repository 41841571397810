@font-face {
  font-family: "Neue Haas Display Mediu";
  src: url("../fonts/neue-haas-grotesk/NeueHaasDisplayMedium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Neue Haas Display light";
  src: url("../fonts/neue-haas-grotesk/NeueHaasDisplayLight.ttf")
    format("truetype");
}

@font-face {
  font-family: "Neue Haas Display Roman";
  src: url("../fonts/neue-haas-grotesk/NeueHaasDisplayRoman.ttf")
    format("truetype");
}

@font-face {
  font-family: "Neue Haas Display Black";
  src: url("../fonts/neue-haas-grotesk/NeueHaasDisplayBlack.ttf")
    format("truetype");
}

:root {
  --base-font-poiretone: "Poiret One" !important;
  --base-font-poppins: "Alike" !important;
  /* --base-font-poppins: "Poppins" !important; */
  --base-font-neuehaasmedium: "Work Sans" !important;
  /* --base-font-neuehaasmedium: "Neue Haas Display Mediu" !important; */
  --base-font-neuehaaslight: "Neue Haas Display light" !important;
  --base-font-neuehaasroman: "Neue Haas Display Roman" !important;
  --base-font-neuehaasblack: "Neue Haas Display Black" !important;
  --base-color-ocean: #647889;
  --base-color-ocean-100: #f4f5f6;
  --base-color-ocean-200: #e0e4e7;
  --base-color-ocean-300: #d1d7dc;
  --base-color-ocean-400: #c1c9d0;
  --base-color-ocean-500: #8393a1;
  --base-color-ocean-600: #748695;
  --base-color-ocean-700: #647889;
  --base-color-ocean-800: #566776;

  --base-color-sand: #f8f8f3;

  --base-color-warm: #b3a18c;
  --base-color-warm-100: #f7f6f4;
  --base-color-warm-200: #f0ece8;
  --base-color-warm-300: #e8e3dd;
  --base-color-warm-400: #e1d9d1;
  --base-color-warm-500: #c2b4a3;
  --base-color-warm-600: #bbaa98;
  --base-color-warm-700: #b3a18c;
  --base-color-warm-800: #9d866c;

  --base-color-glacier: #b8c4c8;
  --base-color-glacier-100: #f8f9fa;
  --base-color-glacier-200: #f1f3f4;
  --base-color-glacier-300: #eaedef;
  --base-color-glacier-400: #e3e7e9;
  --base-color-glacier-500: #c6d0d3;
  --base-color-glacier-600: #bfcace;
  --base-color-glacier-700: #b8c4c8;
  --base-color-glacier-800: #97a9af;

  --base-color-latte: #8e7c6b;
  --base-color-latte-100: #f4f2f0;
  --base-color-latte-200: #e8e5e1;
  --base-color-latte-300: #ddd8d3;
  --base-color-latte-400: #d2cbc4;
  --base-color-latte-500: #a59689;
  --base-color-latte-600: #99897a;
  --base-color-latte-700: #8e7c6b;
  --base-color-latte-800: #807060;

  --base-color-eerie: #212529;
  --base-color-eerie-100: #f5f5f5;
  --base-color-eerie-200: #e0e0e1;
  --base-color-eerie-300: #bcbebf;
  --base-color-eerie-400: #a6a8a9;
  --base-color-eerie-500: #4d5154;
  --base-color-eerie-600: #373b3e;
  --base-color-eerie-700: #212529;
  --base-color-eerie-800: #19181b;
}

.folyeo-logo {
  font-family: var(--base-font-poiretone) !important;
  font-size: 35px !important;
  line-height: auto !important;
  letter-spacing: 0.2em !important;
  color: var(--base-color-ocean) !important;
  font-weight: 700 !important;
}
