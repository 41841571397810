.subject-editor .ql-container.ql-snow {
  min-height: 80px !important;
}

.message-editor .ql-container.ql-snow {
  min-height: 380px !important;
}

.subject-editor .quill {
  border: 1px solid #DFE2E6;
  border-radius: 10px;
}

.subject-editor .ql-container {
  border: none; 
  border-radius: 10px;
}

.subject-editor .ql-snow {
  border: none;
}

.subject-editor .ql-container .ql-editor {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  color: #353a3f !important;
}

.message-editor .ql-container.ql-snow {
  min-height: 308px !important;
} 

.message-editor .quill {
  border: 1px solid #DFE2E6;
  border-radius: 10px;
}
.message-editor  .ql-snow {
  border: none;
}

.message-editor .quill .ql-toolbar.ql-snow{ 
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 1px solid #DFE2E6 !important;
}
 

.message-editor .ql-container .ql-editor {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  color: #353a3f !important;
}

variable {
  padding: 1px 6px !important;
  background-color: #eaecef !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 20px !important;
}

.variables-dropdown .MuiPaper-root::-webkit-scrollbar {
  width: 5px;
}
.variables-dropdown .MuiPaper-root::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.variables-dropdown .MuiPaper-root::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 2px;
} 
 

.course-description .quill .ql-toolbar {
  background-color: #F4F5F6 !important;
}

.course-description .quill .ql-toolbar {
  background-color: #F4F5F6 !important;
}

.course-description .quill .ql-container {
  background-color: #FFF !important;
}

