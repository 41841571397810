:root{
    --font-work-sans: "Work Sans", sans-serif;
    --font-alike: "Alike", serif;
    --font-mukta: "Mukta", sans-serif;
    --font-firo: "Fira Code", serif;
    --bg-black: #000;
    --color-orange: #D97855;
    --color-black: #000;
    --color-gray: #6E757C;  
    
    --font-nanum : "Nanum Myeongjo", sans-serif;
    --font-source : "Source Sans 3", sans-serif;
    --font-source_sans_3 : "Source Sans 3", sans-serif;
    --font-poppins : "Poppins", sans-serif;
    --font-alegreya_sans : "Alegreya Sans", sans-serif;
    --font-alegreya : "Alegreya", sans-serif;
    --font-archivo_narrow : "Archivo Narrow", sans-serif;
    --font-archivo : "Archivo", sans-serif;
    --font-biorhyme : "BioRhyme", serif;
    --font-cardo : "Cardo", serif;
    --font-cormorant : "Cormorant", serif;
    --font-eczar : "Eczar", serif;
    --font-fira_sans : "Fira Sans", sans-serif;
    --font-fraunces : "Fraunces", serif;
    --font-karla : "Karla", sans-serif;
    --font-libre_baskerville : "Libre Baskerville", serif;
    --font-libre_franklin : "Libre Franklin", sans-serif;
    --font-lora : "Lora", sans-serif;
    --font-manrope : "Manrope", sans-serif;
    --font-merriweather : "Merriweather", serif;
    --font-neuton : "Neuton", serif;
    --font-open_sans : "Open Sans", sans-serif;
    --font-pt_sans : "PT Sans", sans-serif;
    --font-raleway : "Raleway", sans-serif;
    --font-roboto : "Roboto", sans-serif;
    --font-source_serif_4 : "Source Serif 4", sans-serif;
    --font-space_mono : "Space Mono", monospace;
    --font-spectral : "Spectral", serif;
    --font-work_sans : "Work Sans", sans-serif;
    --font-lato: "Lato", sans-serif;
    --font-weight-n4rmal: 400
}  

.readmore-content-package-details {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: normal !important;
    color: #19181B !important;
    line-height: normal !important;
}

.readmore-content-package-details span.show-more-less-clickable {
    color : var(--color-orange);
    text-decoration: none;
} 

.heading-1 {
    font-family: var(--font-alike) !important;
    font-size: 40px !important;
    line-height: 42px !important;
    letter-spacing: -1% !important;
    font-weight: 400 !important;
}

.heading-2 {
    font-family: var(--font-alike) !important;
    font-size: 30px !important;
    line-height: 28px !important;
    letter-spacing: 0% !important;
    font-weight: 400 !important;
}

.heading-3 {
    font-family: var(--font-alike) !important;
    font-size: 26px !important;
    line-height: 26px !important;
    letter-spacing: -1% !important;
    font-weight: 400 !important;
}

.heading-4 {
    font-family: var(--font-alike) !important;
    font-size: 22px !important;
    line-height: 24px !important;
    letter-spacing: 2% !important;
}

.heading-5 {
    font-family: var(--font-alike) !important;
    font-size: 20px !important;
    line-height: auto !important;
    letter-spacing: 2% !important;
}

.heading-6 {
    font-family: var(--font-work-sans) !important;
    font-size: 18px !important;
    line-height: auto !important;
    letter-spacing: 2% !important;
    font-weight: 400 !important;
}

.body-lg {
    font-family: var(--font-work-sans) !important;
    font-size: 16px !important;
    line-height: auto !important;
    letter-spacing: 3% !important;
}

.body-md {
    font-family: var(--font-work-sans) !important;
    font-size: 14px !important;
    line-height: auto !important;
    letter-spacing: 4% !important;
    color: var(--base-color-eerie-500) !important;
}

.body-sm {
    font-family: var(--font-work-sans) !important;
    font-size: 12px !important;
    line-height: auto !important;
    letter-spacing: 2% !important;
    color: var(--base-color-eerie-500) !important;
}

.button-1 {
    font-family: var(--font-work-sans) !important;
    font-size: 15px !important;
    line-height: auto !important;
    letter-spacing: 2% !important;
}

.button-2 {
    font-family: var(--font-work-sans) !important;
    font-size: 15px !important;
    line-height: auto !important;
    letter-spacing: 2% !important;
}

.button-3 {
    font-family: var(--font-work-sans) !important;
    font-size: 14px !important;
    line-height: auto !important;
    letter-spacing: 3% !important;
}

.sub-heading {
    font-family: var(--base-font-poppins) !important;
    font-size: 10px !important;
    line-height: 14px !important;
    letter-spacing: 7% !important;
    font-weight: 400 !important;
}